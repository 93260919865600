import React from 'react';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ucFirst } from '../../utils/caseconverters';
import styles from './FactBox.module.scss';

const FactBox = ({ html = '', background = 'blue50' }) => (
    <div
        className={classNames(styles['FactBox'], {
            [styles[`FactBox--` + ucFirst(background)]]: background,
        })}
        dangerouslySetInnerHTML={{ __html: html }}
    />
);

FactBox.propTypes = {
    background: PropTypes.oneOf(['blue50', 'green50', 'orange50', 'purple50']),
    html: PropTypes.string,
};

export default FactBox;
